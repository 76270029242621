import "src/application.css"

import 'modulor'

import 'plugins'
import 'shared'

import 'models'
import 'view_objects'

import {} from 'jquery-ujs'

import Turbolinks from 'turbolinks'
Turbolinks.start()

import MutationObserver from 'lib/modulor/mutation_observer'
MutationObserver.start()
