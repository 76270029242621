import 'lib/modulor/image_gallery'

import Plugin from 'lib/modulor/plugin'

export default class Content extends Plugin
  @defaults =
    debug: false
    name: 'ImagesModule__Nav'

  @selector: ".images_module__nav"

  on_init: ->
    @$element.on "click.#{@options.name}", ".images_module__nav__next", (e) =>
      e.preventDefault()
      @get_images_container().trigger("go_to_next_slide")

    @$element.on "click.#{@options.name}", ".images_module__nav__prev", (e) =>
      e.preventDefault()
      @get_images_container().trigger("go_to_prev_slide")

  get_images_module: -> @$element.closest('.images_module__content')
  get_images_container: -> @get_images_module().find(".images_module__attachment_images")

Content.register()
