import { default as WebModule__Content } from 'models/modulor/web_module/content'

export class Content extends WebModule__Content
  @defaults =
    debug: false
    name: 'DesignStudioListModule__Content'

  @selector: ".design_studio_list_module__content"

  on_init: ->
    @$element.on "click.#{@options.name}", ".link_to_all", (e) =>
      e.preventDefault()

      if @$element.attr("data-expanded")
        @$element.removeAttr("data-expanded")
      else
        @$element.attr("data-expanded", true)

Content.register()
