import Plugin from 'lib/modulor/plugin'

export class AttachmentImages extends Plugin
  @defaults =
    debug: false
    name: 'CoverImagesModule__AttachmentImages'

  @selector: ".cover_images_module__attachment_images"

  on_init: ->
    @$element.css "cursor", "pointer"

    @$element.on "click.#{@options.name}", (e) =>
      @hide()

  on_destroy: ->
    @$element.css "cursor", "initial"

  get_set_session_path: -> @$element.data('set-session-path')

  hide: ->
    @set_session()
    @$element.remove()

  set_session: ->
    return unless url = @get_set_session_path()
    $.post
      dataType: 'js'
      url: url
      data: { _method: 'PUT' }

AttachmentImages.register()
